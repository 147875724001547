<template>
  <v-card
    flat
    class="d-flex jsutify-start"
  >
    <v-card
      tile
      width="95%"
      class="d-flex align-start flex-column"
    >
      <v-card-title>
        <menu-title title="playground/testing1"></menu-title>
      </v-card-title>

      <v-card-text>
        <span>Hey, you found the admin playground. Expect nothing but half-baked experimental features here. Have fun!</span>
      </v-card-text>

      <v-card-actions>
        <v-btn
          outlined
          color="#b31616"
          class="ml-2"
          @click="dialog=!dialog"
        >Toggle dialog</v-btn>
      </v-card-actions>

      <dialog-drag
        v-if="dialog"
        title="some dialog"
        id="d1"
        :options="{buttonPin: false, buttonClose: false, width: 700}"
      >
        <v-card
          outlined
          height="100%"
          elevation="4"
        >
          <v-card-title>test</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Some text</span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  clearable
                  hide-details="auto "
                  value="some input here"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#b31616"
              outlined
              @click="dialog=false"
              class="ml-2"
            >Close</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2"
            >Random submit button</v-btn>
          </v-card-actions>
        </v-card>
      </dialog-drag>

    </v-card>
    <v-card
      tile
      width="5%"
      height="100vh"
      class="d-flex align-start flex-column"
      style="background: #00000020;"
    >
      <drop-area
        style="width: 100%; height: 100%"
        @drop="itemDropped"
      >

      </drop-area>
    </v-card>
  </v-card>
</template>

<script>
import menuTitle from "../../../components/helpers/menu_title.vue";
import DialogDrag from "vue-dialog-drag";
import DropArea from "vue-dialog-drag/dist/vue-drop-area.umd";
export default {
  components: {
    DialogDrag,
    DropArea,
    menuTitle,
  },
  data: () => ({
    dialog: false,
    parked: [],
  }),
  methods: {
    itemDropped: function (id) {
      console.log("dropped id: " + id);
    },
  },
};
</script>

<style src="./Testing1_styles1.css"></style>
<style src="./Testing1_styles2.css"></style>
<style>
.sideways_text {
  transform: rotate(90deg);
}
</style>